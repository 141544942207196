/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmptyTrashRequest } from '../models/empty-trash-request';
import { GetNotificationByIdResponse } from '../models/get-notification-by-id-response';
import { GetNotificationStatsResponse } from '../models/get-notification-stats-response';
import { GetNotificationResponse } from '../models/get-paged-notifications-response';
import { PostNotificationsRequest } from '../models/post-notifications-request';


/**
 * This lists and explains endpoints that relate to retrieving and updating notifications.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationsEndpointApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getNotifications
   */
  static readonly GetNotificationsPath = '/notifications';

  /**
   * show a list of notifications for the logged-in user.
   *
   * show a list of notifications for the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications$Response(params?: {
    application: string;


    /**
     * Pagination cursor pointing to the next page
     */
    next?: string;

    /**
     * Filter parameter for filtering notifications based on read, unread and archived.
     */
    filter?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetNotificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationsEndpointApiService.GetNotificationsPath, 'get');
    if (params) {
      rb.header('application', params.application, {});
      rb.query('next', params.next, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNotificationResponse>;
      })
    );
  }

  /**
   * show a list of notifications for the logged-in user.
   *
   * show a list of notifications for the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications(params?: {
    application: string;


    /**
     * Pagination cursor pointing to the next page
     */
    next?: string;

    /**
     * Filter parameter for filtering notifications based on read, unread and archived.
     */
    filter?: string;
    context?: HttpContext
  }
): Observable<GetNotificationResponse> {

    return this.getNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<GetNotificationResponse>) => r.body )
    );
  }

  /**
   * Path part for operation postNotifications
   */
  static readonly PostNotificationsPath = '/notifications';

  /**
   * update notifications for the logged-in user.
   *
   * update notifications for the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNotifications$Response(params?: {
    context?: HttpContext
    body?: PostNotificationsRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationsEndpointApiService.PostNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * update notifications for the logged-in user.
   *
   * update notifications for the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNotifications(params?: {
    context?: HttpContext
    body?: PostNotificationsRequest
  }
): Observable<void> {

    return this.postNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getNotification
   */
  static readonly GetNotificationPath = '/notification/{notificationId}';

  /**
   * fetch the notification by notification Id.
   *
   * show the notification by notification Id for the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotification$Response(params: {

    /**
     * The notificationId for the notication to be fetched
     */
    notificationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetNotificationByIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationsEndpointApiService.GetNotificationPath, 'get');
    if (params) {
      rb.path('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNotificationByIdResponse>;
      })
    );
  }

  /**
   * fetch the notification by notification Id.
   *
   * show the notification by notification Id for the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotification(params: {

    /**
     * The notificationId for the notication to be fetched
     */
    notificationId: string;
    context?: HttpContext
  }
): Observable<GetNotificationByIdResponse> {

    return this.getNotification$Response(params).pipe(
      map((r: StrictHttpResponse<GetNotificationByIdResponse>) => r.body as GetNotificationByIdResponse)
    );
  }

  /**
   * Path part for operation getNotificationStats
   */
  static readonly GetNotificationStatsPath = '/notifications/stats';

  /**
   * fetch stats for notifications.
   *
   * show the notification stats the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotificationStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationStats$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetNotificationStatsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationsEndpointApiService.GetNotificationStatsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNotificationStatsResponse>;
      })
    );
  }

  /**
   * fetch stats for notifications.
   *
   * show the notification stats the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotificationStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationStats(params?: {
    context?: HttpContext
  }
): Observable<GetNotificationStatsResponse> {

    return this.getNotificationStats$Response(params).pipe(
      map((r: StrictHttpResponse<GetNotificationStatsResponse>) => r.body as GetNotificationStatsResponse)
    );
  }

  /**
   * Path part for operation emptyTrashNotifications
   */
  static readonly EmptyTrashNotificationsPath = '/notifications/trash/empty';

  /**
   * Empty trash for notifications for the logged-in user.
   *
   * Empty trash for notifications for the logged-in user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emptyTrashNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emptyTrashNotifications$Response(params?: {
    context?: HttpContext
    body?: EmptyTrashRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationsEndpointApiService.EmptyTrashNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Empty trash for notifications for the logged-in user.
   *
   * Empty trash for notifications for the logged-in user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emptyTrashNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emptyTrashNotifications(params?: {
    context?: HttpContext
    body?: EmptyTrashRequest
  }
): Observable<void> {

    return this.emptyTrashNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
